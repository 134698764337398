<template>

  <DefaultCrudView
    @create-new="newUnomiConfig"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="unomiConfigs"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >

        <template v-slot:item.tenant_id="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getTenantTitle(item.tenant_id) }}
          </span>
        </template>

        <template v-slot:item.customer_properties="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getCustomerPropertiesTitles(item.customer_properties) }}
          </span>
        </template>
      
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editUnomiConfigs(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="deleteUnomiConfigModal(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
          
        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" @submit="save">
        <template v-slot:content>
          <DynamicFormContent 
            :fields="fields"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('Delete Unomi Config')"
        ref="deleteModal"
        @submit="remove"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_unomi_config') }} <b>{{ selectedUnomiConfig ? selectedUnomiConfig.unomi_id : '' }}</b> ?
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

</style>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import CustomerPropertyActions from '@/store/cdp/customer_property/actions-types'
import UnomiConfigActions from '@/store/cdp/unomi_config/actions-types'
import UnomiConfigMutations from '@/store/cdp/unomi_config/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "UnomiConfig",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapState({
      fields: state => state.cdp.unomi_config.fields,
      unomiConfigs: state => state.cdp.unomi_config.unomiConfigs,
      selectedUnomiConfig: state => state.cdp.unomi_config.selectedUnomiConfig,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('cdp_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "CdpSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('unomi_configs'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      options: {},
      itemsLength: 0,
      tenants: [],
      headers: [
        {text: 'Unomi Id', value: 'unomi_id'},
        {text: 'Version', value: 'version'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Type', value: 'type'},
        {text: 'Customer properties', value: 'customer_properties'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "create_new",
          eventToEmit: "create-new",
        }
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG](null)
  
    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[UnomiConfigMutations.SET_FIELD_TENANTS](tenants.items)

        this.tenants = [...tenants.items]

        this.loading = false
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('cdp/customer_property', [
      CustomerPropertyActions.GET_ALL
    ]),

    ...mapActions('cdp/unomi_config', [
      UnomiConfigActions.GET_ALL,
      UnomiConfigActions.SAVE_UNOMI_CONFIG,
      UnomiConfigActions.DELETE_UNOMI_CONFIG,
    ]),

    ...mapMutations('cdp/unomi_config', [
      UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG,
      UnomiConfigMutations.SET_FIELD_TENANTS,
      UnomiConfigMutations.SET_FIELD_CUSTOMER_PROPERTIES,
    ]),

    newUnomiConfig() {
      this.[UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG](null)
      this.getCustomerProperties()
      this.$refs.modal.openModal()
    },

    getCustomerProperties() {
      this.loading = true
      this.[CustomerPropertyActions.GET_ALL]()
        .then((result) => {
          this.[UnomiConfigMutations.SET_FIELD_CUSTOMER_PROPERTIES](result.items)
          this.loading = false
        })
    },

    save() {
      this.[UnomiConfigActions.SAVE_UNOMI_CONFIG]()
        .then(() => {
          let message = this.selectedUnomiConfig ? 'Customer Property edited' : 'Customer Property created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      this.loading = true

      this.[UnomiConfigActions.GET_ALL](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editUnomiConfigs(unomiConfig) {
      this.[UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG](unomiConfig)

      this.getCustomerProperties()

      this.$refs.modal.openModal()
    },

    deleteUnomiConfigModal(unomiConfig) {
      this.[UnomiConfigMutations.SET_SELECTED_UNOMI_CONFIG](unomiConfig)

      this.$refs.deleteModal.openModal()
    },

    remove() {
      this.[UnomiConfigActions.DELETE_UNOMI_CONFIG]()
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('unomi_config_deleted'))

          this.$refs.deleteModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    getModalTitle() {
      return this.selectedUnomiConfig ? this.$t('Edit') : this.$t('new_unomi_config')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getCustomerPropertiesTitles(customerProperties) {
      let titles = []

      customerProperties.forEach((item) => titles.push(item.customer_property_title))

      return titles.join(', ')
    }
  }
}

</script>